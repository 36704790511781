<template>
  <div class="row printme">
    <div class="col-12" v-if="hamla == ''">
        <div class="col-12">
            <br><br><br><br>
        </div>
        <div class="row">
            <div class="col-12 col-lg-6 g text-right" v-if="hamla_1">
                <button class="btn btn-lg btn-primary" @click="hamla = '1'; gg()">
                    البشائر
                </button>
            </div>
            <div class="col-12 col-lg-6 g text-left" v-if="hamla_2">
                <button class="btn btn-lg btn-success" @click="hamla = '2'; gg()">
                    الميسر
                </button>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-9 c g" v-if="hamla != ''">
        <div class="col-12 text-center g">
            <button class="btn btn-primary btn-sm hideme" @click="print()">
                <i class="fa fa-print"></i>
                طباعة الصفحة
            </button>
        </div>
        <div class="card">
            <div class="card-header">
                <h5>
                     الحافلات
                </h5>
            </div>
            <div class="card-body">
                <div class="table-responsive col-12">
                    <table class="table table-hover table-sm table-bordered striped">
                        <thead>
                            <th>
                                الحافلة
                            </th>
                            <th>
                                النوع
                            </th>
                            <th>
                                الرجال
                            </th>
                            <th>
                                النساء
                            </th>
                            <th>
                                الجنسيات
                            </th>
                            <th>
                                العدد الاقصى
                            </th>
                            <th>
                                العدد الحالي
                            </th>
                            <th>
                                الحالة
                            </th>
                        </thead>
                        <tbody>
                            <template v-for="u in busses">
                                <tr :key="u._id">
                                    <td>
                                        {{ u.title }}
                                    </td>
                                    <td>
                                        {{ u.mix ? 'عوائل' : 'رجال' }}
                                    </td>
                                    <td>
                                        {{ u.mens }}
                                    </td>
                                    <td>
                                        {{ u.womans }}
                                    </td>
                                    <td>
                                        <span v-for="(n, u) in u.nationals" :key="n">
                                            {{ u }} ({{n}})<br>
                                        </span>
                                    </td>
                                    <td>
                                        {{ u.limit }}
                                    </td>
                                    <td>
                                        {{ u.users.length }}
                                    </td>
                                    <td v-html="u.limit - u.users.length == 0 ? `<span class='text-secondary'>مكتملة</span>` : (u.limit - u.users.length > 0 ? `<span class='text-success'>متبقي ` + (u.limit - u.users.length) + '</span>' : `<span class='text-danger'>هناك ` + ((u.limit - u.users.length).toString().replace('-', ``)) + ' زيادة </span>')">
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 col-lg-3 c g" v-if="hamla != ''">
        <div class="card">
            <div class="card-header">
                <h5>
                     الحجاج الغير موزعين ({{ hamla == '1' ? 'البشائر' : 'الميسر' }})
                </h5>
            </div>
            <div class="card-body">
                <div class="table-responsive col-12">
                    <table class="table table-hover table-sm table-bordered striped">
                        <thead>
                            <tr>
                                <th>
                                    الاجمالي
                                </th>
                                <th>
                                    {{ users.length }}
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    الرجال
                                </th>
                                <th>
                                    {{ users.map(function(x){
                                        if(x.gender == 'ذكر'){
                                            return x;
                                        }else{
                                            return false;
                                        }
                                    }).filter(function(x){return x}).length }}
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    النساء
                                </th>
                                <th>
                                    {{ users.map(function(x){
                                        if(x.gender == 'انثى'){
                                            return x;
                                        }else{
                                            return false;
                                        }
                                    }).filter(function(x){return x}).length }}
                                </th>
                            </tr>
                        </thead>
                    </table>
                    <hr>
                    <table class="table table-hover table-sm table-bordered striped">
                        <thead>
                            <tr>
                                <th>
                                    سكن رجال منى
                                </th>
                                <th>
                                    {{ camps.map(function(x){
                                        if(x.gender == 1 && x.type == '1'){
                                            return x;
                                        }else{
                                            return false;
                                        }
                                    }).filter(function(x){return x}).length }}
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    سكن نساء منى
                                </th>
                                <th>
                                    {{ camps.map(function(x){
                                        if(x.gender == 2 && x.type == '1'){
                                            return x;
                                        }else{
                                            return false;
                                        }
                                    }).filter(function(x){return x}).length }}
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    سكن رجال عرفات
                                </th>
                                <th>
                                    {{ camps.map(function(x){
                                        if(x.gender == 1 && x.type == '2'){
                                            return x;
                                        }else{
                                            return false;
                                        }
                                    }).filter(function(x){return x}).length }}
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    سكن نساء عرفات
                                </th>
                                <th>
                                    {{ camps.map(function(x){
                                        if(x.gender == 2 && x.type == '2'){
                                            return x;
                                        }else{
                                            return false;
                                        }
                                    }).filter(function(x){return x}).length }}
                                </th>
                            </tr>
                        </thead>
                    </table>
                    <hr>
                    <table class="table table-hover table-sm table-bordered striped">
                        <thead>
                            <tr>
                                <th>
                                    حجاج بدون حافلات
                                </th>
                                <th style="cursor:pointer" @click="$router.push('/arrange-problem')">
                                    {{ users.map(function(x){
                                        if(x.bus_id == ""){
                                            return x;
                                        }else{
                                            return false;
                                        }
                                    }).filter(function(x){return x}).length }}
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    حجاج بدون سكن منى
                                </th>
                                <th style="cursor:pointer" @click="$router.push('/arrange-problem')">
                                    {{ users.map(function(x){
                                        if(x.camp_id == ""){
                                            return x;
                                        }else{
                                            return false;
                                        }
                                    }).filter(function(x){return x}).length }}
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    حجاج بدون سكن عرفات
                                </th>
                                <th style="cursor:pointer" @click="$router.push('/arrange-problem')">
                                    {{ users.map(function(x){
                                        if(x.camp2_id == ""){
                                            return x;
                                        }else{
                                            return false;
                                        }
                                    }).filter(function(x){return x}).length }}
                                </th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-6 c g" v-if="hamla != ''">
        <div class="card">
            <div class="card-header">
                <h5>
                     سكن منى/مكة
                </h5>
            </div>
            <div class="card-body">
                <div class="table-responsive col-12">
                    <table class="table table-hover table-sm table-bordered striped">
                        <thead>
                            <th>
                                الصالة
                            </th>
                            <th>
                                النوع
                            </th>
                            <th>
                                العدد الاقصى
                            </th>
                            <th>
                                العدد الحالي
                            </th>
                            <th>
                                الحالة
                            </th>
                        </thead>
                        <tbody>
                            <template v-for="u in camps">
                                <tr :key="u._id" v-if="u.type == '1'">
                                    <td>
                                        {{ u.title }}
                                    </td>
                                    <td>
                                        {{ u.gender == 1 ? 'رجال' : 'نساء' }}
                                    </td>
                                    <td>
                                        {{ u.limit }}
                                    </td>
                                    <td>
                                        {{ u.users.length }}
                                    </td>
                                    <td v-html="u.limit - u.users.length == 0 ? `<span class='text-secondary'>مكتملة</span>` : (u.limit - u.users.length > 0 ? `<span class='text-success'>متبقي ` + (u.limit - u.users.length) + '</span>' : `<span class='text-danger'>هناك ` + ((u.limit - u.users.length).toString().replace('-', ``)) + ' زيادة </span>')">
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-6 c g" v-if="hamla != ''">
        <div class="card">
            <div class="card-header">
                <h5>
                     سكن عرفات
                </h5>
            </div>
            <div class="card-body">
                <div class="table-responsive col-12">
                    <table class="table table-hover table-sm table-bordered striped">
                        <thead>
                            <th>
                                الصالة
                            </th>
                            <th>
                                النوع
                            </th>
                            <th>
                                العدد الاقصى
                            </th>
                            <th>
                                العدد الحالي
                            </th>
                            <th>
                                الحالة
                            </th>
                        </thead>
                        <tbody>
                            <template v-for="u in camps">
                                <tr :key="u._id" v-if="u.type == '2'">
                                    <td>
                                        {{ u.title }}
                                    </td>
                                    <td>
                                        {{ u.gender == 1 ? 'رجال' : 'نساء' }}
                                    </td>
                                    <td>
                                        {{ u.limit }}
                                    </td>
                                    <td>
                                        {{ u.users.length }}
                                    </td>
                                    <td v-html="u.limit - u.users.length == 0 ? `<span class='text-secondary'>مكتملة</span>` : (u.limit - u.users.length > 0 ? `<span class='text-success'>متبقي ` + (u.limit - u.users.length) + '</span>' : `<span class='text-danger'>هناك ` + ((u.limit - u.users.length).toString().replace('-', ``)) + ' زيادة </span>')">
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import {  VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            bus_id: "",
            camp_id: "",
            camp2_id: "",
            user: JSON.parse(localStorage.getItem("user")),
            users: [],
            current: {},
            busses: [],
            camps: [],
            hamla: "",
            hamla_2: checkPer("hamla_2"),
            hamla_1: checkPer("hamla_1")
        }
    },
    created(){
        
    },
    methods: {
        save_current(){
            var g = this;
            $.post(api + '/admin/users/save', {
                jwt: g.user.jwt,
                id: g.current._id,
                user: g.current
            }).then(function(a){
                g.gg();
            })
        },
        gg(){
            var g = this;
            $.post(api + '/admin/users/not-arranged', {
                jwt: g.user.jwt,
                hamla: g.hamla
            }).then(function(r){
                g.users = r.response
            })
            $.post(api + '/admin/busses/list-with-users', {
                jwt: g.user.jwt,
                hamla: g.hamla
            }).then(function(r){
                g.busses = r.response
            })
            $.post(api + '/admin/camps/list-with-users', {
                jwt: g.user.jwt,
                hamla: g.hamla
            }).then(function(r){
                g.camps = r.response
            })
        },
        print(){
        var divToPrint = $(".printme");
        var newWin= window.open("");
        newWin.document.write(`
        <style>
        table, td, th {
            border: 1px solid;
        }
        table {
            width: 100%;
            border-collapse: collapse;
        }
        html,body{
            direction: rtl
        }
        .hideme{
            display:none
        }
        </style>
        `)
        newWin.document.write(divToPrint.html());
        newWin.document.close()
        newWin.print();
    }
    }
}
</script>

<style>

</style>